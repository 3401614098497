/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call */
import * as Sentry from '@sentry/browser';
import { Controller } from 'stimulus';

declare global {
  interface Window {
    zE: (...params: any) => void;
    zEACLoaded: any;
  }
}

let hasLoadedScriptTag = false;
let hasAddedLoadHandler = false;

class ControllerBase extends Controller {
  declare readonly scriptTagTarget: HTMLInputElement;
}

export default class extends (Controller as typeof ControllerBase) {
  static targets = ['scriptTag'];

  static values = {
    key: String,
  };

  connect() {
    // Remove the zEACLoaded global that the Zendesk widget uses to short-circuit subsequent loads.
    // This allows the Zendesk widget to reload after Turbolinks visits.
    //
    // https://mentalized.net/journal/2019/04/26/zendesk-web-widget-turbolinks/
    window.zEACLoaded = undefined;

    try {
      this.setAuthenticateUserCallback();
      hasAddedLoadHandler = true;
      hasLoadedScriptTag = true;
    } catch {
      if (!hasLoadedScriptTag) {
        this.scriptTagTarget.addEventListener('load', () => {
          if (!hasAddedLoadHandler) {
            this.setAuthenticateUserCallback();
            hasAddedLoadHandler = true;
          }
        });
        hasLoadedScriptTag = true;
      }
    }
  }

  private setAuthenticateUserCallback() {
    window.zE('messenger:on', 'open', () => {
      this.authenticateUser();
    });
  }

  private authenticateUser() {
    // @ts-ignore: Unsafe call of an `any` typed value
    window.zE('messenger', 'loginUser', callback => {
      fetch('/ultimate_authentication')
        .then(response => {
          if (response.ok) {
            return response.text();
          }

          window.zE('messenger', 'close');
          window.zE('messenger', 'hide');
          return null;
        })
        .then(data => {
          if (data !== null) callback(data);
        })
        .catch(error => Sentry.captureException(error));
    });
  }
}
